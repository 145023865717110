import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 30px;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 0 15px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    button,
    a {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      svg {
      }
    }
    & > * {
      margin: 0 15px;
    }
  }
`

export const ButtonWrap = styled.div`
  button {
    opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
    cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
    pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "")};
  }
  &.disabled {
    button,
    a {
      opacity: 0.6;
    }
  }
`
export const Arrow = styled.img`
  margin: 0 0.5rem;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    margin: 0;
  }
`
export const Text = styled.span`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    display: none;
  }
`
