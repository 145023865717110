import React from "react"
import {Arrow, ButtonWrap, Container, Text} from "./styles"
import {PrimaryButton} from "components/Buttons"
import RightArrow from "assets/img/svg/arr_right.svg"
import LeftArrow from "assets/img/svg/arr_left.svg"

export const NavButtons = ({previousMenteeSlug, nextMenteeSlug}) => {
  const previousUrl = previousMenteeSlug ? `/podopieczni/${previousMenteeSlug}` : '';
  const nextUrl = nextMenteeSlug ? `/podopieczni/${nextMenteeSlug}` : '';

  return (
    <Container>
      <ButtonWrap isDisabled={previousUrl === ''}>
        <PrimaryButton to={previousUrl}>
          <Arrow src={LeftArrow} alt="arrow"/>
          <Text>Poprzedni podopieczny</Text>
        </PrimaryButton>
      </ButtonWrap>
      <ButtonWrap isDisabled={nextUrl === ''}>
        <PrimaryButton to={nextUrl}>
          <Text>Następny podopieczny</Text>
          <Arrow src={RightArrow} alt="arrow"/>
        </PrimaryButton>
      </ButtonWrap>
    </Container>
  );
};
