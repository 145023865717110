import React from "react"
import SEO from "components/seo"
import { SectionWrapper } from "shared/Styles/Wrappers"
import { StyledGlobalWrapper } from "components/MenteesList/styles"
import { PageHeader } from "components/PageHeader"
import { MenteeDetails } from "components/MenteeDetails"
import { NavButtons } from "components/NavButtons"

const Mentee = ({ pageContext }) => {
  const { mentee, nextMenteeSlug, previousMenteeSlug } = pageContext

  return (
    <>
      <SEO title={`${mentee.name}, ${mentee.age}`} description={mentee.about} />
      <PageHeader>Nasi podopieczni</PageHeader>
      <StyledGlobalWrapper>
        <SectionWrapper>
          <MenteeDetails mentee={mentee} />
          <NavButtons
            nextMenteeSlug={nextMenteeSlug}
            previousMenteeSlug={previousMenteeSlug}
          />
        </SectionWrapper>
      </StyledGlobalWrapper>
    </>
  )
}

export default Mentee
